import React, { Component } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import { setLockImg } from '../store/reducers/variantSlice';

const ImageComponent = (props) => {
  const dispatch = useDispatch();
  const image = props.props;
  const [open, setOpen] = React.useState(false);
  const handleShowDialog = () => {
    setOpen(!open);
    dispatch(setLockImg(false));
  };
  const filename = image.substr(image.lastIndexOf('/') + 1);
  var fileType;
  var fileName;
  var fileSize;
  const downloadFile = () => {
    new Network().DownloadFileInChat(image, fileType, fileName);
  };
  var isUriImage = function (image) {
    var uri = image.split('?')[0];
    //moving on, split the uri into parts that had dots before them
    var parts = uri.split('.');
    //get the last part ( should be the extension )
    var extension = parts[parts.length - 1];
    //define some image types to test against
    var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp', 'jfif'];
    //check if the extension matches anything in the list.
    if (imageTypes.indexOf(extension) !== -1) {
      return true;
    } else {
      var fileTypes = ['txt', 'pdf', 'docx'];
      if (fileTypes.indexOf(extension) !== -1) {
        fileType = extension;
      }
      parts = parts[parts.length - 2];
      parts = parts.toString().split('/');
      fileName = parts[parts.length - 1];
      // Логина для получения размера файла - надо сделать на бэке
      // var req = new XMLHttpRequest();
      // req.open('GET', image, false);
      // req.send();
      // fileSize = req.getResponseHeader('content-length');
      return false;
    }
  };
  return (
    <div className="chat-images">
      {isUriImage(image) ? (
        <img className="chat-image" src={image} onClick={handleShowDialog} />
      ) : (
        <p className="file-area">
          <a className="file-icon" onClick={downloadFile} />
          <a onClick={downloadFile} className="download-black">
            {filename}
          </a>
          {/* Логика для отображения размера файла */}
          {/* {fileSize < 1024 ?
                <p>{fileSize} B</p> :
                <p>{fileSize / 1024} Mb</p>
              } */}
        </p>
      )}
      {open && (
        <dialog className="dialog" open onClick={handleShowDialog}>
          <img className="dialog-image" src={image} onClick={handleShowDialog} />
        </dialog>
      )}
    </div>
  );
};

export default ImageComponent;
