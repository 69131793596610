import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Network from './Requests';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';

const Analytics = () => {
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date().toISOString().substring(0, 10));
  const [endDate, setEndDate] = React.useState(new Date().toISOString().substring(0, 10));
  const [intervalType, setIntervalType] = React.useState(4);
  const companyId = useSelector((state) => state.project.companyId);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeDate = (changedInternalType) => {
    let date = new Date();
    date.setHours(3, 0, 0);
    switch (changedInternalType) {
      case 1:
        setEndDate(new Date().toISOString().substring(0, 10));
        date.setDate(date.getDate() - 1);
        setStartDate(date.toISOString().substring(0, 10));
        break;
      case 2:
        setEndDate(new Date().toISOString().substring(0, 10));
        date.setDate(date.getDate() - 7);
        setStartDate(date.toISOString().substring(0, 10));
        break;
      case 3:
        setEndDate(new Date().toISOString().substring(0, 10));
        date.setMonth(date.getMonth() - 1);
        setStartDate(date.toISOString().substring(0, 10));
        break;
    }
    setIntervalType(changedInternalType);
  };

  const downloadReport = async () => {
    if (intervalType !== 0) {
      let date = new Date();
      let start = new Date(startDate);
      let end = new Date(endDate);

      let startSend = new Date(
        Date.UTC(
          Number(start.getFullYear()),
          Number(start.getMonth()),
          Number(start.getDate()),
          0,
          0,
          0,
          0,
        ),
      );

      let endSend = new Date(
        Date.UTC(
          Number(end.getFullYear()),
          Number(end.getMonth()),
          Number(end.getDate()),
          23,
          59,
          59,
          0,
        ),
      );
      var filename = `Выборка_от_${new Intl.DateTimeFormat().format(
        date,
      )} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.xlsx`;
      await new Network().GetChatsExcel(intervalType, companyId, startSend, endSend, filename);
    }
  };

  function handleStartDateChange(e) {
    setStartDate(e.target.value);
    setIntervalType(4);
  }

  function handleEndDateChange(e) {
    setEndDate(e.target.value);
    setIntervalType(4);
  }

  return (
    <div>
      <Button className="bm-item menu-item" onClick={handleClickOpen}>
        Аналитика
      </Button>
      <Dialog open={open} keepMounted onClose={handleClose}>
        <DialogContent>
          <form className="analytics-form analytics-input-label-float">
            <TextField
              className="analytics-input"
              label="Дата начала"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className="analytics-input analytics-input-float analytics-input-label-float"
              label="Дата конца"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
          <div className="analytics-list">
            <Button
              className="analytics-button"
              onClick={() => {
                changeDate(1);
              }}>
              День
            </Button>
            <Button
              className="analytics-button"
              onClick={() => {
                changeDate(2);
              }}>
              Неделя
            </Button>
            <Button
              className="analytics-button"
              onClick={() => {
                changeDate(3);
              }}>
              Месяц
            </Button>
          </div>
          <hr className="analytics-hr" />
          <Button
            className="analytics-button analytics-download"
            onClick={() => {
              downloadReport();
              handleClose();
            }}>
            Загрузить
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Analytics;
