import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenPublicForm, setCloseActionPanel } from '../../store/reducers/saveGuids';
import QRCode from 'qrcode.react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Network from '../Requests';
import { globalConfig } from '../../configuration/config';
import Checkbox from '@material-ui/core/Checkbox';

const PublicLink = (props) => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.guids.openPublicForm);
  const calculationList = useSelector((state) => state.variant?.list);
  const calculationGuid = useSelector((state) => state.variant?.current?.calculationGuid);
  const companyId = useSelector((state) => state.project.companyId);
  const hidden = useRef(null);

  const [select, setSelect] = useState('Chat');
  const [link, setLink] = useState('');
  const [checked, setChecked] = React.useState(false);

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  const copyLink = (e) => {
    var copyText = document.getElementById('copyLink');
    window.navigator.clipboard.writeText(copyText.value);
    var tooltip = document.getElementById('TooltipText');
    tooltip.innerHTML = 'Скопировано';
  };

  const outFunc = (e) => {
    var tooltip = document.getElementById('TooltipText');
    tooltip.innerHTML = 'Копировать';
  };

  const currentVariantIndex = () => {
    var a;
    if (calculationList) {
      var a = calculationList.findIndex((i) => i.calculationGuid == calculationGuid);
    }

    if (a != -1) {
      return a + 1;
    }
  };

  useEffect(() => {
    const getLink = async () => {
      const link = await new Network().GetPublicLink(calculationGuid, select, companyId);
      const insert = `${globalConfig.config.common.base_url}/web/public/?id=${link}`;
      setLink(insert);
    };
    if (open) {
      getLink();
    }
  }, [select, calculationGuid, open]);

  const openQR = () => {
    if (!hidden) {
      return;
    }

    hidden.current.classList.add('qr-view--display-view');
  };

  const goLink = () => {
    const getLink = async () => {
      const link = await new Network().GetPublicLink(calculationGuid, select, companyId);
      const go = await new Network().FollowPublicLink(link, companyId);
      props.openGroup(go.projectGuid, go.guidGroup, go.guidCalculation);
      dispatch(setOpenPublicForm(!open));
      dispatch(setCloseActionPanel(true));
    };
    getLink();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(setOpenPublicForm(!open));
      }}>
      <DialogContent className="public-link">
        <div className="share">
          <div className="form--row form--row--left">
            <div>Общественная ссылка на вариант №{currentVariantIndex()}</div>
            <span>Вид ссылки: </span>
            <FormControlLabel
              checked={select === 'Chat'}
              value="chat"
              control={<Radio color="primary" onClick={handleChange} />}
              label="2D"
              labelPlacement="bottom"
            />
            <FormControlLabel
              checked={select === '3D'}
              value="3D"
              control={<Radio color="primary" onClick={handleChange} />}
              label="3D"
              labelPlacement="bottom"
            />
            <FormControlLabel
              checked={select === '3D'}
              value="3D"
              control={<Radio color="primary" onClick={handleChange} />}
              label="AR"
              disabled
              labelPlacement="bottom"
            />
            <FormControlLabel
              checked={select === '3D'}
              value="3D"
              control={<Radio color="primary" onClick={handleChange} />}
              label="VR"
              disabled
              labelPlacement="bottom"
            />
            <div></div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChangeChecked}
                  name="stories"
                  color="primary"
                  disabled
                />
              }
              label="Запустить сториз"
            />
          </div>
          <div className="form--row">
            <input type="submit" name="time" value="Получить QR" onClick={() => openQR()} />
            <div
              class="button"
              onClick={() => {
                goLink();
              }}>
              Перейти
            </div>
          </div>
          <div className="qr-view qr-view--display" ref={hidden}>
            {open && <QRCode value={link} renderAs={'svg'} />}
            {open && link && (
              <>
                <input id="copyLink" disabled value={link} />
                <div className="tooltip">
                  <i onClick={(e) => copyLink(e)} onMouseOut={(e) => outFunc(e)}>
                    <span class="tooltiptext" id="TooltipText">
                      Копировать
                    </span>
                  </i>
                </div>
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default PublicLink;
